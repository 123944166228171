import dayjs from 'dayjs';
import React, { useState } from 'react';
import Header from '../../common/Header';
import { formatPhone } from '../../common/helpers/format';
import List from '../../common/list/List';
import ListItem from '../../common/list/ListItem';
import { Contact } from '../../types/Contact.type';
import ContactModal from './ContactModal';
import useContacts from './hooks/useContacts';
import utc from 'dayjs/plugin/utc';
import PillBox from '../../common/form/PillBox';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/outline';
import { Checkbox } from '../../common/form/Checkbox';
dayjs.extend(utc);

const ContactList = () => {
	const {
		data,
		isFetching,
		isFetchingNextPage,
		fetchNextPage,
		hasNextPage,
		markAsContacted,
		reOrder,
		filterContacted,
		searchTerm,
		refetch,
	} = useContacts();
	const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
	const [markingAsContacted, setMarkingAsContacted] = useState<
		number | undefined
	>();
	const [orderDesc, setOrderDesc] = useState<boolean>(true);

	const closeModal = () => {
		setSelectedContact(null);
	};

	const markContacted = async (contactId: number) => {
		setMarkingAsContacted(contactId);
		await markAsContacted(contactId);
		setMarkingAsContacted(undefined);
		refetch();
	};

	return (
		<>
			<Header
				title="Contacts"
				className="pb-6"
				rightSide={
					<div className="flex flex-col gap-1 items-end">
						<div
							onClick={() => {
								reOrder(orderDesc ? 'asc' : 'desc');
								setOrderDesc((prev) => !prev);
							}}
						>
							<span className="cursor-pointer text-gray-500 hover:text-gray-600">
								{orderDesc ? (
									<div className="flex gap-1 justify-center items-center">
										<span className="text-sm">Oldest first</span>
										<ArrowUpIcon className="h-5 w-5" />
									</div>
								) : (
									<div className="flex gap-1 justify-center items-center">
										<span className="text-sm">Newest first</span>
										<ArrowDownIcon className="h-5 w-5" />
									</div>
								)}
							</span>
						</div>
						<div className="flex gap-2 space-between items-center">
							<span className="text-sm text-gray-500">Not contacted</span>
							<Checkbox
								value={searchTerm === 'contacted'}
								onChange={() => filterContacted(searchTerm !== 'contacted')}
							/>
						</div>
					</div>
				}
			/>
			<List
				isFetching={isFetching}
				isFetchingNextPage={!!isFetchingNextPage}
				fetchNextPage={fetchNextPage}
				hasNextPage={hasNextPage}
			>
				{data?.pages?.map((page, i: number) => (
					<React.Fragment key={i}>
						{page?.data?.map((row: Contact, rowKey: number) => (
							<div
								key={row.id}
								className="cursor-pointer"
								onClick={() => setSelectedContact(row)}
							>
								<ListItem index={rowKey}>
									<div className="grid grid-cols-1 sm:grid-cols-1 md:flex items-center justify-between space-x-4">
										<div className="min-w-0">
											<div className="relative group flex items-center space-x-4">
												<div className="space-y-3">
													<div className="flex items-center space-x-3">
														<span className="block">
															<h2 className="text-sm font-medium leading-4">
																<span className="absolute inset-0" />
																{row.name}
															</h2>
														</span>
													</div>
													<div className="text-sm leading-4 text-gray-500 group-hover:text-gray-900 font-medium">
														{row.email && (
															<span className="sm:whitespace-nowrap block sm:inline">
																{row.email}
															</span>
														)}
														{row.email && row.phone && (
															<span className="hidden sm:inline mx-1">/</span>
														)}
														{row.phone && (
															<span className="sm:whitespace-nowrap block sm:inline">
																{formatPhone(row.phone)}
															</span>
														)}
													</div>
												</div>
											</div>
										</div>
										<div className="hidden">
											<svg
												className="h-5 w-5 text-gray-400"
												viewBox="0 0 20 20"
												fill="currentColor"
											>
												<path
													fillRule="evenodd"
													d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
													clipRule="evenodd"
												/>
											</svg>
										</div>
										<div className="sm:flex flex-col flex-shrink-0 items-end space-y-3">
											<p className="flex items-center space-x-4">
												<span className="relative text-sm leading-4 text-gray-500 hover:text-gray-900 font-medium">
													&nbsp;
												</span>
											</p>
											<PillBox
												className={
													row.contacted
														? 'bg-primary-600 cursor-none focus:ring-primary-500 shadow-sm'
														: 'bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 shadow-sm'
												}
												text={
													row.contacted
														? `Contacted by ${row.contacted}`
														: 'Mark as contacted'
												}
												onClick={() => {
													if (!row.contacted) {
														markContacted(row.id);
													}
												}}
												loading={markingAsContacted === row.id}
											/>
											<p className="flex text-gray-500 text-sm leading-4 space-x-2">
												Sent{' '}
												{dayjs.utc(row.createDate).local().format('MM/DD/YYYY')}{' '}
												at {dayjs.utc(row.createDate).local().format('hh:mma')}
											</p>
										</div>
									</div>
								</ListItem>
							</div>
						))}
					</React.Fragment>
				))}
			</List>
			{selectedContact && (
				<ContactModal contact={selectedContact} close={closeModal} />
			)}
		</>
	);
};

export default ContactList;
