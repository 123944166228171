import 'cleave.js/dist/addons/cleave-phone.us';
import Cleave from 'cleave.js/react';
import React, { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { FormError } from '../types/ErrorResponse.type';
import { ErrorIcon } from './ErrorIcon';
import { FieldErrors } from './FieldErrors';

export const CALLING_CODES = [
	'+1',
	'+7',
	'+20',
	'+27',
	'+30',
	'+31',
	'+32',
	'+33',
	'+34',
	'+36',
	'+39',
	'+40',
	'+41',
	'+43',
	'+44',
	'+45',
	'+46',
	'+47',
	'+48',
	'+49',
	'+51',
	'+52',
	'+53',
	'+54',
	'+55',
	'+56',
	'+57',
	'+58',
	'+60',
	'+61',
	'+62',
	'+63',
	'+64',
	'+65',
	'+66',
	'+81',
	'+82',
	'+84',
	'+86',
	'+90',
	'+91',
	'+92',
	'+93',
	'+94',
	'+95',
	'+98',
	'+211',
	'+212',
	'+213',
	'+216',
	'+218',
	'+220',
	'+221',
	'+222',
	'+223',
	'+224',
	'+225',
	'+226',
	'+227',
	'+228',
	'+229',
	'+230',
	'+231',
	'+232',
	'+233',
	'+234',
	'+235',
	'+236',
	'+237',
	'+238',
	'+239',
	'+240',
	'+241',
	'+242',
	'+243',
	'+244',
	'+245',
	'+246',
	'+248',
	'+249',
	'+250',
	'+251',
	'+252',
	'+253',
	'+254',
	'+255',
	'+256',
	'+257',
	'+258',
	'+260',
	'+261',
	'+262',
	'+263',
	'+264',
	'+265',
	'+266',
	'+267',
	'+268',
	'+269',
	'+290',
	'+291',
	'+297',
	'+298',
	'+299',
	'+350',
	'+351',
	'+352',
	'+353',
	'+354',
	'+355',
	'+356',
	'+357',
	'+358',
	'+359',
	'+370',
	'+371',
	'+372',
	'+373',
	'+374',
	'+375',
	'+376',
	'+377',
	'+378',
	'+379',
	'+380',
	'+381',
	'+382',
	'+383',
	'+385',
	'+386',
	'+387',
	'+389',
	'+420',
	'+421',
	'+423',
	'+500',
	'+501',
	'+502',
	'+503',
	'+504',
	'+505',
	'+506',
	'+507',
	'+508',
	'+509',
	'+590',
	'+591',
	'+592',
	'+593',
	'+595',
	'+597',
	'+598',
	'+599',
	'+670',
	'+672',
	'+673',
	'+674',
	'+675',
	'+676',
	'+677',
	'+678',
	'+679',
	'+680',
	'+681',
	'+682',
	'+683',
	'+685',
	'+686',
	'+687',
	'+688',
	'+689',
	'+690',
	'+691',
	'+692',
	'+850',
	'+852',
	'+853',
	'+855',
	'+856',
	'+880',
	'+886',
	'+960',
	'+961',
	'+962',
	'+963',
	'+964',
	'+965',
	'+966',
	'+967',
	'+968',
	'+970',
	'+971',
	'+972',
	'+973',
	'+974',
	'+975',
	'+976',
	'+977',
	'+992',
	'+993',
	'+994',
	'+995',
	'+996',
	'+998',
	'+1-242',
	'+1-246',
	'+1-264',
	'+1-268',
	'+1-284',
	'+1-340',
	'+1-345',
	'+1-441',
	'+1-473',
	'+1-649',
	'+1-664',
	'+1-670',
	'+1-671',
	'+1-684',
	'+1-721',
	'+1-758',
	'+1-767',
	'+1-784',
	'+1-787',
	'+1-939',
	'+1-809',
	'+1-829',
	'+1-849',
	'+1-868',
	'+1-869',
	'+1-876',
	'+44-1481',
	'+44-1534',
	'+44-1624',
];

type Props = {
	name: string;
	className: string;
	label: string;
	value?: string;
	onChange: (val: string) => void;
	errors?: FormError;
	disabled?: boolean;
};

export const Phone: React.FC<Props> = ({
	name,
	className,
	label,
	value,
	onChange,
	errors,
	disabled,
}) => {
	const [number, setNumber] = useState<string>();
	const [phoneRegionCode, setPhoneRegionCode] = useState<string>('+1');
	const ref = React.useRef();

	useEffect(() => {
		if (value) {
			const [newRegionCode, ...num] = value.split(' ');
			const newNumber = num.join(' ').trim()
			setPhoneRegionCode(newRegionCode);
			setNumber(newNumber);
		}
	}, [value]);

	const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
		// phone number has changed (not country code)
		const val = event.target.value;
		const valCleaned = val.replace(/\s/g, '')
		onChange(`${phoneRegionCode} ${valCleaned}`);
	};

	const onChangeCountry = (event: ChangeEvent<HTMLSelectElement>) => {
		// country code has changed
		let val = event.target.value;
		// in case the user changed the country code from one that allows 15 digits
		// to +1, which only allows 10
		let newNumber = val === '+1' ? number?.slice(0, 10) : number
		setNumber(newNumber)
		onChange(`${val} ${newNumber}`);
	};

	const preventInvalidChars = (event: KeyboardEvent<HTMLInputElement>) => {
		const { key } = event;
		const isInvalid = (
			// phone number cannot begin with a 1
			(!number && key === '1')
		);

		if (isInvalid) {
			event.preventDefault();
		}
	};

	return (
		<div className={className}>
			<div>
				<label
					htmlFor={name}
					className="block text-sm font-medium leading-5 text-gray-900 sm:mt-px sm:pt-2"
				>
					{label}
				</label>
			</div>
			<div>
				<div className="relative rounded-md shadow-sm">
					<div className="absolute inset-y-0 left-0 flex items-center">
						<select
							aria-label="Country"
							className="form-select h-full py-0 pl-3 pr-6 border-transparent bg-transparent text-gray-500 sm:text-sm sm:leading-5 w-28"
							onChange={onChangeCountry}
							value={phoneRegionCode}
						>
							{CALLING_CODES.map((code) => (
								<option value={code} key={code}>
									{code}
								</option>
							))}
							<option value="">Other</option>
						</select>
					</div>
					<Cleave
						data-cy={`select-${name}`}
						value={number}
						onChange={onChangeValue}
						onKeyPress={preventInvalidChars}
						key={phoneRegionCode}
						options={{
							blocks: phoneRegionCode === '+1' ? [3, 3, 4] : [15],
							numericOnly: true,
						}}
						className={[
							'block w-full border-gray-300 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50 sm:text-sm sm:leading-5 pl-28',
							errors
								? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:ring-red'
								: '',
						].join(' ')}
						disabled={disabled}
					/>
					{errors && <ErrorIcon />}
				</div>
				<FieldErrors errors={errors} />
			</div>
		</div>
	);
};
