import React from 'react';
import { Checkbox } from '../../../common/form/Checkbox';
import { Input } from '../../../common/form/Input';
import { ValueOf } from '../../../common/types/ValueOf.type';
import { SubOptions } from '../../accounts/Account.type';
import { Option } from '../subscription-checkout/SubscriptionCheckout';
import { ExclamationCircleIcon, StarIcon } from '@heroicons/react/outline';

type Props = {
	options: Option[];
	additionalLocationsActive?: boolean;
	onChange: (key: keyof SubOptions) => (val: ValueOf<SubOptions>) => void;
	onChangeLocations: (val: number) => void;
	totalPrice: number;
	updateSubscription: () => void;
	loadingCheckout?: boolean;
	value: SubOptions;
	setAdditionalLocationsActive: (value: React.SetStateAction<boolean>) => void;
	patchValue: (values: Partial<SubOptions>) => void;
	isDirty?: boolean;
	currSubscriber?: boolean;
	bailedOptions?: boolean;
	listFreeOptions?: boolean;
};

const SubscriptionCheckoutDesktop = ({
	options,
	onChange,
	totalPrice,
	updateSubscription,
	loadingCheckout = false,
	value,
	additionalLocationsActive,
	setAdditionalLocationsActive,
	patchValue,
	isDirty,
	currSubscriber,
	bailedOptions,
	listFreeOptions,
}: Props) => {
	return (
		<div className="max-w-7xl mx-auto py-24 px-4 sm:px-10 lg:px-12 hidden sm:flex flex-col items-center">
			<h2 className="text-left font-extrabold text-gray-900 sm:text-2xl sm:leading-none sm:tracking-tight lg:text-xl mb-6">
				Manage your subscription package.
			</h2>
			{listFreeOptions ? (
				<div className="rounded-md bg-primary-200 p-4 flex flex-col shadow-md items-center">
					<StarIcon className="h-5 w-5 text-primary-500" />
					<span className="font-bold text-primary-600">
						Included in your plan for free:
					</span>
					<div className="">
						<div className="ml-3">
							<div className="mt-2 text-sm text-gray-900 flex flex-col items-center gap-1">
								{options.map(
									(opt) =>
										opt.freeLevel &&
										!value[opt.val as keyof SubOptions] && (
											<span className="">{opt.freeLevel}</span>
										)
								)}
							</div>
						</div>
					</div>
				</div>
			) : (
				<></>
			)}
			{bailedOptions ? (
				<div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
					<div className="flex">
						<div className="flex-shrink-0">
							<ExclamationCircleIcon
								className="h-5 w-5 text-yellow-400"
								aria-hidden="true"
							/>
						</div>
						<div className="ml-3">
							<p className="text-sm text-yellow-700">
								It looks like you didn't quite finish your subscription when you
								signed up, but we saved your choices for you!{' '}
								<span
									className="font-medium text-yellow-700 underline hover:text-yellow-600 cursor-pointer"
									onClick={() => updateSubscription()}
								>
									Click here to finish subscribing with these options
								</span>
								, or choose some new ones below!
							</p>
						</div>
					</div>
				</div>
			) : (
				<></>
			)}
			<fieldset className="border-b border-gray-200 w-3/4 flex flex-col">
				<div className="divide-y divide-gray-200">
					{options.map((option, i) => (
						<div
							key={i}
							className="relative flex justify-center items-center py-4"
						>
							<div className="mr-3 flex h-5 items-center">
								<Checkbox
									name={option.val}
									onChange={(val) => {
										if (option.val === 'additionalLocations') {
											setAdditionalLocationsActive((prev: boolean) => !prev);
											if (value.additionalLocations || 0 > 0) {
												patchValue({ additionalLocations: 0 });
											} else {
												patchValue({ additionalLocations: 1 });
											}
										} else if (option.count) {
											if (value[option.val as keyof SubOptions] || 0 > 0) {
												patchValue({ [option.val as keyof SubOptions]: 0 });
											} else {
												onChange(option.val as keyof SubOptions)(option.count);
											}
										} else {
											onChange(option.val as keyof SubOptions)(val);
										}
									}}
									value={
										option.val === 'additionalLocations'
											? additionalLocationsActive
											: (value[
													option.val as keyof SubOptions
											  ] as unknown as boolean)
									}
								/>
							</div>
							<div className="min-w-0 flex-1 text-sm">
								<label htmlFor="comments" className="font-bold text-gray-700">
									{option.title}
								</label>
								<p id="comments-description" className="text-gray-500">
									{option.descr}
								</p>
								{option.val === 'additionalLocations' &&
									additionalLocationsActive && (
										<div className="w-1/5">
											<Input
												name={option.val}
												label={''}
												value={value.additionalLocations}
												className="max-w-1/5"
												clickIcon
												type="number"
												onChange={onChange('additionalLocations')}
												min="1"
												step="1"
												iconRight
											/>
										</div>
									)}
							</div>
							<div className="self-end">
								${option.count ? option.price * option.count : option.price}/
								{option.recurrence}
							</div>
						</div>
					))}
					<div className="bg-primary-400 rounded-md shadow-sm h-12 mt-8 text-black font-bold flex justify-between items-center p-4">
						<p>Total price:</p>
						<p>${totalPrice}.00</p>
					</div>
				</div>
				<div
					className={[
						'w-1/3 h-8 bg-black self-center mt-4 rounded-md text-white flex justify-center items-center text-sm font-bold cursor-pointer',
						loadingCheckout
							? 'bg-gray-500 cursor-not-allowed'
							: !isDirty
							? 'cursor-not-allowed'
							: '',
					].join(' ')}
					onClick={() => isDirty && updateSubscription()}
				>
					{currSubscriber ? 'Update membership' : 'Create new membership'}
				</div>
			</fieldset>
		</div>
	);
};

export default SubscriptionCheckoutDesktop;
