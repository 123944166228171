import { ThumbUpIcon } from '@heroicons/react/solid';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import MobileSubscriptionAlert from '../../../common/alerts/MobileSubscriptionAlert';
import { MeContext } from '../../../common/contexts/me.context';
import {
	ToastContext,
	ToastTypes,
} from '../../../common/contexts/toast.context';
import { useForm } from '../../../common/hooks/useForm';
import { OpenClosedStates } from '../../../common/OpenClosedStates';
import { updateSubscription } from '../../../services/account.service';
import { SubOptions } from '../../accounts/Account.type';
import SMSAlertModal from './SMSAlertModal';
import SubscriptionCheckoutDesktop from './SubscriptionCheckoutDesktop';
import SubscriptionCheckoutMobile from './SubscriptionCheckoutMobile';
import UpdateSubscriptionModal from './UpdateSubscriptionModal';

type Prices = {
	additionalLocations?: string;
	unlimitedListings?: string;
	unlimitedImages?: string;
	dealerDirectPrivacy?: string;
	apiAccess?: string;
	clickAnalytics?: string;
	video?: string;
	smsNotifications?: string;
};

const prices: Prices = {
	additionalLocations: process.env.REACT_APP_PRODUCTS_ADDITIONAL_LOCATIONS,
	unlimitedListings: process.env.REACT_APP_PRODUCTS_UNLIMITED_LISTINGS,
	unlimitedImages: process.env.REACT_APP_PRODUCTS_UNLIMITED_IMAGES,
	clickAnalytics: process.env.REACT_APP_PRODUCTS_CLICK_ANALYTICS,
	dealerDirectPrivacy: process.env.REACT_APP_PRODUCTS_DEALER_DIRECT_PRIVACY,
	apiAccess: process.env.REACT_APP_PRODUCTS_API_ACCESS,
	video: process.env.REACT_APP_PRODUCTS_VIDEO,
	smsNotifications: process.env.REACT_APP_SMS_NOTIFICATIONS,
};

export type Option = {
	title: string;
	val: string;
	descr: string;
	price: number;
	recurrence: string;
	count?: number;
	subtitle?: string;
	freeLevel?: string;
	priceSubtitle?: string;
	basic?: boolean;
};

const options: Option[] = [
	{
		title: 'Unlimited listings',
		freeLevel: 'Up to 5 listings',
		val: 'unlimitedListings',
		descr: 'Unlock unlimited additional product listings.',
		price: 50,
		recurrence: 'mo',
	},
	{
		title: 'Additional locations',
		freeLevel: '1 location',
		val: 'additionalLocations',
		descr: 'Perfect if you manage multiple sites.',
		price: 10,
		recurrence: 'mo (per location)',
		count: 1,
	},
	{
		title: 'Unlimited images',
		freeLevel: '1 image per listing',
		val: 'unlimitedImages',
		descr:
			"Help buyers know what they're getting by showing more of your sheds.",
		price: 10,
		recurrence: 'mo',
	},
	{
		title: 'Click analytics',
		val: 'clickAnalytics',
		descr:
			'Curious how your inventory is doing? Click analytics show information on how many times your products are being viewed.',
		price: 5,
		recurrence: 'mo',
	},
	{
		title: 'Dealer direct privacy',
		val: 'dealerDirectPrivacy',
		descr:
			'Hide competing inventory on your Dealer Direct Page. Only yours will be visible.',
		price: 10,
		recurrence: 'mo',
	},
	{
		title: 'API access',
		val: 'apiAccess',
		descr:
			'Connect your inventory instantly with popular platforms like Cal and Shed Suite.',
		price: 25,
		recurrence: 'mo',
	},
	{
		title: 'Video',
		val: 'video',
		descr:
			'Offering video of your inventory will help to increase customer engagement and sales.',
		price: 10,
		recurrence: 'mo',
	},
	{
		title: 'Text notifications',
		val: 'smsNotifications',
		descr:
			'Add the ability to receive text messages when customers submit a contact form on your product pages. (Price is based on $5/mo per active location.)',
		price: 5,
		recurrence: 'mo',
		count: 1,
	},
];

const SubscriptionCheckout = () => {
	const [additionalLocationsActive, setAdditionalLocationsActive] =
		useState<boolean>(false);
	const [updateModal, setUpdateModal] = useState<OpenClosedStates>(
		OpenClosedStates.Closed
	);
	const [specialAlerts, setSpecialAlerts] = useState<string[]>();
	const [alertUp, setAlertUp] = useState<boolean>(false);
	const { me, updateMe } = useContext(MeContext);
	const { createToast } = useContext(ToastContext);

	const {
		value,
		onChange,
		patchValue,
		patchValueClean,
		registerSubmit,
		isDirty,
		isSubmitting,
	} = useForm<SubOptions>({
		additionalLocations: 0,
		unlimitedListings: false,
		unlimitedImages: false,
		clickAnalytics: false,
		dealerDirectPrivacy: false,
		apiAccess: false,
		video: false,
		smsNotifications: 0,
	});
	const [smsAlertModal, setSmsAlertModal] = useState<OpenClosedStates>(
		OpenClosedStates.Closed
	);
	const [locationsWithoutPhone, setLocationsWithoutPhone] = useState<any[]>();

	const bailedOptions = useMemo(() => {
		if (me?.account?.subscription?.bailedOptions) {
			return true;
		}
	}, [me]);

	const listFreeOptions = useMemo(() => {
		if (
			options.find((opt) => opt.subtitle && !value[opt.val as keyof SubOptions])
		) {
			return true;
		}
	}, [value]);

	useEffect(() => {
		if (me?.account?.subscription?.bailedOptions) {
			Object.keys(me.account.subscription?.bailedOptions)?.forEach((opt) => {
				patchValue({
					[opt]:
						me?.account?.subscription?.bailedOptions?.[opt as keyof SubOptions],
				});
			});
		} else {
			Object.keys(
				me?.account?.subscription?.options || {
					additionalLocations: 0,
					unlimitedListings: false,
					unlimitedImages: false,
					clickAnalytics: false,
					dealerDirectPrivacy: false,
					apiAccess: false,
					video: false,
					smsNotifications: 0,
				}
			)?.forEach((opt) => {
				patchValueClean({
					[opt]: me?.account?.subscription?.options?.[opt as keyof SubOptions],
				});
			});
		}
	}, [me, patchValue, patchValueClean]);

	useEffect(() => {
		if (value?.additionalLocations || (0 >= 1 && !additionalLocationsActive)) {
			setAdditionalLocationsActive(true);
		}
	}, [additionalLocationsActive, value]);

	const totalPrice = useMemo(() => {
		let total = 0;
		options.forEach((opt) => {
			if (opt.val === 'additionalLocations') {
				total += (value.additionalLocations || 0) * 10;
			} else if (opt.val === 'smsNotifications') {
				total += (value.smsNotifications || 0) * 5;
			} else {
				if (value[opt.val as keyof SubOptions]) {
					total += opt.price;
				}
			}
		});
		return total;
	}, [value]);

	const displayOptions = useMemo(() => {
		return options.map((opt) => {
			let displayOption;
			if (opt.val === 'smsNotifications') {
				displayOption = {
					...opt,
					count:
						me?.account?.locations?.filter((location: any) => location.active)
							?.length || 1,
				};
			} else {
				displayOption = opt;
			}
			return displayOption;
		});
	}, [me?.account?.locations]);

	const onChangeLocations = (val: number) => {
		patchValue({ additionalLocations: val });
	};

	const onSubmit = async (val: Partial<SubOptions>) => {
		const res = await updateSubscription({
			accountId: me?.account.id,
			subOptions: value,
			currSubscriber: !!me?.account.subscription?.currSubscriber,
		});
		if (res?.url) {
			window.location.href = res.url;
		}
		return res;
	};

	const onSuccess = async (val: any) => {
		const subItems = val?.updatedSubscription?.items?.data;

		const options: SubOptions = subItems.reduce(
			(obj: SubOptions, curr: any) => {
				const key = Object.keys(prices).find(
					(key) => prices[key as keyof Prices] === curr.plan.id
				);
				obj[key as keyof SubOptions] = curr.quantity;
				return obj;
			},
			{
				additionalLocations: 0,
				unlimitedListings: false,
				unlimitedImages: false,
				clickAnalytics: false,
				dealerDirectPrivacy: false,
				apiAccess: false,
				video: false,
				smsNotifications: false,
			}
		);
		updateMe('account', {
			...me.account,
			subscription: { ...me.account.subscription, options },
		});
		setUpdateModal(OpenClosedStates.Closed);
		if (val?.smsAdded) {
			const locationsWithoutPhone = me?.account?.locations?.reduce(
				(locs: any, curr: any) => {
					if (!curr.phone && !curr.contactFormTextNumber) {
						locs.push(curr.address);
					}
					return locs;
				},
				[]
			);
			setLocationsWithoutPhone(locationsWithoutPhone);
			setSmsAlertModal(OpenClosedStates.Open);
		}
		const screen = window.screen;
		if (screen.width < 640) {
			setAlertUp(true);
			window.scrollTo({ top: 100 });
		} else {
			createToast({
				title: 'Success!',
				description: <>Successfully updated subscription!</>,
				type: ToastTypes.Success,
				duration: 5000,
			});
		}
	};

	const onFail = async (val: any) => {
		setUpdateModal(OpenClosedStates.Closed);
		createToast({
			title: 'Failed!',
			description: <>Failed to update subscription!</>,
			type: ToastTypes.Fail,
			duration: 5000,
		});
	};

	const confirmUpdateSubscription = () => {
		if (!me.account.subscription?.currSubscriber) {
			return registerSubmit(onSubmit, { onSuccess, onFail })();
		}
		const currSubOptions = me.account.subscription?.options;
		const alerts: any = [];
		if (currSubOptions?.apiAccess && !value.apiAccess) {
			alerts.push('apiAccess');
		}
		if (
			(currSubOptions?.additionalLocations || 0) >
			(value.additionalLocations || 0)
		) {
			alerts.push('additionalLocations');
		}
		if (
			(currSubOptions?.smsNotifications || 0) > 0 &&
			(value.smsNotifications || 0) < 1
		) {
			alerts.push('smsNotifications');
		}
		setSpecialAlerts(alerts);
		setUpdateModal(OpenClosedStates.Open);
	};

	return alertUp ? (
		<MobileSubscriptionAlert
			title="Thank you for your upgrade!"
			rejectMessage="Return to dashboard"
			rejectLink="/"
			icon={<ThumbUpIcon className="h-12 w-12 text-black" />}
			close={() => setAlertUp(false)}
		/>
	) : (
		<>
			<div className="bg-gray-50">
				<SubscriptionCheckoutMobile
					additionalLocationsActive={additionalLocationsActive}
					options={displayOptions}
					onChange={onChange}
					onChangeLocations={onChangeLocations}
					value={value}
					totalPrice={totalPrice}
					updateSubscription={confirmUpdateSubscription}
					loadingCheckout={isSubmitting}
					setAdditionalLocationsActive={setAdditionalLocationsActive}
					patchValue={patchValue}
					isDirty={isDirty}
					currSubscriber={!!me?.account?.subscription?.currSubscriber}
					updateModalOpen={updateModal === OpenClosedStates.Open}
				/>
				<SubscriptionCheckoutDesktop
					additionalLocationsActive={additionalLocationsActive}
					options={displayOptions}
					onChange={onChange}
					onChangeLocations={onChangeLocations}
					value={value}
					totalPrice={totalPrice}
					updateSubscription={confirmUpdateSubscription}
					loadingCheckout={isSubmitting}
					setAdditionalLocationsActive={setAdditionalLocationsActive}
					patchValue={patchValue}
					isDirty={isDirty}
					currSubscriber={!!me?.account?.subscription?.currSubscriber}
					bailedOptions={bailedOptions}
					listFreeOptions={listFreeOptions}
				/>
			</div>
			<UpdateSubscriptionModal
				modalState={updateModal}
				specialAlerts={specialAlerts}
				submit={registerSubmit(onSubmit, { onSuccess, onFail })}
				setModalState={setUpdateModal}
				isSubmitting={isSubmitting}
				updatingFromLegacy={
					me?.account?.subscription?.plan === 'pro' ||
					me?.account?.subscription?.plan === 'elite'
				}
			/>
			<SMSAlertModal
				state={smsAlertModal}
				setModalState={setSmsAlertModal}
				locationsWithoutPhone={locationsWithoutPhone}
			/>
		</>
	);
};

export default SubscriptionCheckout;
