import React from 'react';
import Button from '../../common/Button';
import { CompleteSetupForm } from '../../common/CompleteSetup';
import { Input } from '../../common/form/Input';
import { Phone } from '../../common/form/Phone';
import { Select } from '../../common/form/Select';
import ManufacturerAutocomplete from '../../common/ManufacturerAutocomplete';
import { FormErrors } from '../../common/types/ErrorResponse.type';
import { ValueOf } from '../../common/types/ValueOf.type';
import { STATES } from '../../constants';
import { SubmitReturnType } from '../../common/hooks/useForm';

type Props = {
	value: Partial<CompleteSetupForm>;
	onChange: (
		key: keyof CompleteSetupForm
	) => (val: ValueOf<CompleteSetupForm>) => void;
	errors: FormErrors;
	registerSubmit: (
		fnSubmit: (
			values: Partial<{
				email: string;
				password: string;
			}>
		) => SubmitReturnType<
			{
				email: string;
				password: string;
			},
			void
		>,
		{
			onSuccess,
			onFail,
		}: {
			onSuccess?: ((response: any) => void) | undefined;
			onFail?: ((errors: any) => void) | undefined;
		}
	) => (e?: any) => void;
	confirmSignUp: () => void;
	isSubmitting?: boolean;
	isDirty?: boolean;
};

const SignUpFullAccount = ({
	value,
	onChange,
	errors,
	registerSubmit,
	confirmSignUp,
	isSubmitting,
	isDirty,
}: Props) => {
	const onSubmit = () => {
		confirmSignUp();
	};

	return (
		<div className="grid grid-cols-6 gap-2 p-6 bg-white shadow sm:rounded-lg mr-4 my-4 w-full">
			<div className="px-4 sm:px-0 flex gap-2 col-span-6">
				<h3 className="text-lg font-medium leading-6 text-gray-900">
					Account information
				</h3>
				<p className="mt-1 text-sm text-gray-600">
					This will be used to display your company's information within the app
					and also how ShedsForSale.com can contact you.
				</p>
			</div>
			<Input
				name="name"
				value={value.name}
				errors={errors.fieldErrors?.name}
				onChange={onChange('name')}
				className="col-span-6"
				label="Company name *"
			/>
			<Phone
				name="phone"
				value={value.phone}
				errors={errors.fieldErrors?.phone}
				onChange={onChange('phone')}
				className="col-span-6 sm:col-span-3"
				label="Office Phone *"
			/>
			<Phone
				name="cellPhone"
				value={value.cellPhone}
				errors={errors.fieldErrors?.cellPhone}
				onChange={onChange('cellPhone')}
				className="col-span-6 sm:col-span-3"
				label="Cell Phone"
			/>
			<Input
				label="Email *"
				type="email"
				value={value.email}
				onChange={onChange('email')}
				name="email"
				className="col-span-6 sm:col-span-3"
				errors={errors.fieldErrors?.email}
			/>
			<Input
				label="Password *"
				name="password"
				onChange={onChange('password')}
				type="password"
				value={value.password}
				className="col-span-6 sm:col-span-3"
				errors={errors?.fieldErrors?.password}
			/>
			<Input
				label="Address *"
				value={value.address}
				onChange={onChange('address')}
				name="address"
				className="col-span-6"
				errors={errors.fieldErrors?.address}
			/>
			<Input
				label="City *"
				value={value.city}
				onChange={onChange('city')}
				name="city"
				className="col-span-6 sm:col-span-3"
				errors={errors.fieldErrors?.city}
			/>
			<Select
				label="State *"
				value={value.state}
				onChange={onChange('state')}
				name="state"
				className="col-span-6 sm:col-span-2"
				errors={errors.fieldErrors?.state}
				options={STATES}
				limit={1}
			/>
			<Input
				label="Zip *"
				value={value.zip}
				onChange={onChange('zip')}
				name="zip"
				className="col-span-6 sm:col-span-1"
				errors={errors.fieldErrors?.zip}
			/>

			<div className="col-span-6 grid grid-cols-6 gap-6">
				<ManufacturerAutocomplete
					value={value.manufacturers}
					onChange={onChange('manufacturers')}
					errors={errors.fieldErrors?.manufacturers}
					className="col-span-6 h-full"
					label="Manufacturer options for product listings"
					publicRoute
				/>
				{/* <Autocomplete
												options={manufacturerOptions}
												valueProp="id"
												textProp="name"
												label="Manufacturer options for product listings"
												value={value.manufacturers}
												onChange={onChange('manufacturers')}
												name="manufacturer"
												className="col-span-6"
												errors={errors.fieldErrors?.manufacturer}
												isFetchingNextPage={!!isFetchingNextPage}
												isFetching={!!isFetching}
												fetchNextPage={fetchNextPage}
												hasNextPage={hasNextPage}
												onChangeSearch={onSearch}
												searchTerm={searchTerm}
											/> */}
				<div className="relative col-span-6 mt-6">
					<div
						className="absolute inset-0 flex items-center"
						aria-hidden="true"
					>
						<div className="w-full border-t border-gray-300" />
					</div>
				</div>
			</div>
			<div className="col-span-6">
				<Button
					fullWidth
					text="Sign up"
					onClick={onSubmit}
					loading={isSubmitting}
					disabled={!isDirty || isSubmitting}
				/>
			</div>
		</div>
	);
};

export default SignUpFullAccount;
