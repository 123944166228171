import { ChevronRightIcon } from '@heroicons/react/outline';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import WarningAlert from '../../common/alerts/WarningAlert';
import Button, { ButtonColors } from '../../common/Button';
import { MeContext } from '../../common/contexts/me.context';
import Header from '../../common/Header';
import { OpenClosedStates } from '../../common/OpenClosedStates';
import AccountSettingsLocationModal from './AccountSettingsLocationModal';
import AccountSettingsTabs from './AccountSettingsTabs';

type Props = {
	locations: any[];
	saveLocation: (location: any) => void;
	removeLocation: (id: number) => void;
};

const AccountSettingsLocations: React.FC<Props> = ({
	locations,
	saveLocation,
	removeLocation,
}) => {
	const { me } = useContext(MeContext);
	const [selectedLocation, setSelectedLocation] = useState<any>();
	const [locationModalState, setLocationModalState] = useState(
		OpenClosedStates.Closed
	);

	useEffect(() => {
		if (selectedLocation) {
			setLocationModalState(OpenClosedStates.Open);
		}
	}, [selectedLocation]);

	const limitMet = useMemo(() => {
		let limitMetVal = false;
		let limit = 1;
		const activeLocations = locations?.filter((loc) => loc.active);
		if (me?.account?.newPlan) {
			limitMetVal =
				activeLocations?.length >=
				(me?.account?.subscription?.options?.additionalLocations || 0) + 1;
			limit =
				(me?.account?.subscription?.options?.additionalLocations || 0) + 1;
		} else {
			limitMetVal =
				activeLocations?.length >= me?.account?.subscription?.locations;
			limit = me?.account?.subscription?.locations;
		}
		return {
			limitMetVal,
			limit,
		};
	}, [
		locations,
		me?.account?.newPlan,
		me?.account?.subscription?.locations,
		me?.account?.subscription?.options?.additionalLocations,
	]);

	const openLocation = (location: any) => {
		setSelectedLocation(location);
		setLocationModalState(OpenClosedStates.Open);
	};

	return (
		<>
			<Header
				title="Locations"
				rightSide={
					<Button
						text="Add location"
						onClick={() => openLocation({ path: '' })}
						color={ButtonColors.primary}
						disabled={limitMet.limitMetVal}
					/>
				}
			>
				<AccountSettingsTabs
					manufacturer={me?.account?.type === 'Manufacturer'}
					plan={me?.account?.subscription?.plan}
				/>
			</Header>
			<div className="px-6 pt-6 bg-gray-50 h-full">
				{limitMet.limitMetVal && (
					<WarningAlert>
						Subscription limit met. You can only have {limitMet.limit} active
						locations with your current subscription.{' '}
						<Link
							to="/billing"
							className="font-medium underline text-yellow-700 hover:text-yellow-600"
						>
							Update your subscription to add more locations.
						</Link>
					</WarningAlert>
				)}
				<div className="bg-white shadow overflow-hidden sm:rounded-md mt-6">
					<ul className="divide-y divide-gray-200">
						{locations?.map((location) => (
							<li
								key={location?.id}
								onClick={() => setSelectedLocation(location)}
								className={[
									'block hover:bg-gray-50 cursor-pointer',
									location?.active ? 'text-gray-500' : 'text-gray-300',
								].join(' ')}
							>
								<div className="px-4 py-4 flex items-center sm:px-6">
									<div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
										<div className="truncate">
											<div className="flex text-sm">
												<p className="font-medium truncate">
													{location?.address}
												</p>
											</div>
											<div className="mt-2 flex">
												<div className="flex items-center text-sm">
													<p>
														{location?.city}, {location?.state} {location?.zip}
													</p>
												</div>
											</div>
										</div>
									</div>
									<div className="ml-5 flex-shrink-0">
										<ChevronRightIcon
											className="h-5 w-5 text-gray-400"
											aria-hidden="true"
										/>
									</div>
								</div>
							</li>
						))}
					</ul>
				</div>
			</div>
			<AccountSettingsLocationModal
				state={locationModalState}
				setState={setLocationModalState}
				location={selectedLocation}
				saveLocation={saveLocation}
				removeLocation={removeLocation}
				disabled={
					!selectedLocation?.active &&
					locations?.filter((loc) => loc.active)?.length >= limitMet?.limit
				}
				dealerPathPart={me?.account?.path}
				locations={me?.account?.locations}
				hasSms={(me?.account?.subscription?.options?.smsNotifications || 0) > 0}
				setSelectedLocation={setSelectedLocation}
			/>
		</>
	);
};

export default AccountSettingsLocations;
