import React from 'react';
import Button, { ButtonColors } from '../../../common/Button';
import { Input } from '../../../common/form/Input';
import { Toggle } from '../../../common/form/Toggle';
import { formatCurrency } from '../../../common/helpers/format';
import { ValueOf } from '../../../common/types/ValueOf.type';
import { SubOptions } from '../../accounts/Account.type';
import { Option } from '../subscription-checkout/SubscriptionCheckout';

type Props = {
	options: Option[];
	additionalLocationsActive?: boolean;
	onChange: (key: keyof SubOptions) => (val: ValueOf<SubOptions>) => void;
	onChangeLocations: (val: number) => void;
	totalPrice: number;
	updateSubscription: () => void;
	loadingCheckout?: boolean;
	value: SubOptions;
	setAdditionalLocationsActive: (value: React.SetStateAction<boolean>) => void;
	patchValue: (values: Partial<SubOptions>) => void;
	isDirty?: boolean;
	currSubscriber?: boolean;
	updateModalOpen?: boolean;
};

const SubscriptionCheckoutMobile = ({
	options,
	onChange,
	totalPrice,
	updateSubscription,
	loadingCheckout = false,
	value,
	additionalLocationsActive,
	setAdditionalLocationsActive,
	patchValue,
	isDirty,
	currSubscriber,
	updateModalOpen,
}: Props) => {
	return (
		<div className="sm:hidden w-full h-screen flex flex-col">
			<div className="w-full bg-primary-500 h-8 flex justify-between font-bold items-center p-4">
				<p className="text-sm">Starting price:</p>
				<p className="text-lg">Free</p>
			</div>
			<div className="mt-4 flex justify-between p-4 mb-8">
				<div className="w-2/3 flex flex-col gap-4">
					<p className="font-bold text-3xl">Update your account!</p>
					<p className="text-xs">
						We’re now offering more options to give you opportunities to make
						sales. With the free version you’ll still get benefits like brand
						association, listing cloning, social sharing, click analytics
						access, outbound links, and more. But now have the options to build
						your own plan. Explore the options below!
					</p>
				</div>
			</div>
			{options.map((opt, i) => (
				<div
					key={i}
					className="w-5/6 mx-10 mt-4 self-center border-t-gray-900 border-t"
				>
					<div className="flex gap-2 items-center">
						<Toggle
							label=""
							onChange={(val) => {
								if (opt.val === 'additionalLocations') {
									setAdditionalLocationsActive((prev: boolean) => !prev);
									if ((value.additionalLocations || 0) > 0) {
										patchValue({ additionalLocations: 0 });
									} else {
										patchValue({ additionalLocations: 1 });
									}
								} else if (opt.count) {
									if (value[opt.val as keyof SubOptions] || 0 > 0) {
										patchValue({ [opt.val as keyof SubOptions]: 0 });
									} else {
										onChange(opt.val as keyof SubOptions)(opt.count);
									}
								} else {
									onChange(opt.val as keyof SubOptions)(val);
								}
							}}
							value={
								opt.val === 'additionalLocations'
									? ((value.additionalLocations || 0 > 0) as boolean)
									: (value[opt.val as keyof SubOptions] as unknown as boolean)
							}
						/>
						<div>
							<div className="flex flex-col gap-2">
								<p className="font-bold">{opt.title}</p>
								<p>
									{opt.subtitle}{' '}
									<span className="font-bold">{formatCurrency(opt.price)}</span>
								</p>
								<p className="text-xs text-gray-400">{opt.descr}</p>
							</div>
							{opt.val === 'additionalLocations' && (
								<Input
									type="number"
									step="1"
									name=""
									label=""
									value={value.additionalLocations}
									onChange={onChange('additionalLocations')}
									min="1"
									className="w-1/2"
								/>
							)}
						</div>
					</div>
				</div>
			))}
			<div className="flex gap-1 w-5/6 p-4 text-gray-500 text-sm">
				<p className="text-primary-500">+</p>
				<p>
					Also included in free plan is free brand association, listing cloning,
					social sharing, outbound links, and deal direct customer header.
				</p>
			</div>
			<div className="mt-4 mb-10 w-full p-4 flex flex-col items-center gap-2 justify-center">
				<Button
					text={currSubscriber ? 'Update membership' : 'Create new membership'}
					color={ButtonColors.primary}
					onClick={updateSubscription}
				/>
			</div>
		</div>
	);
};

export default SubscriptionCheckoutMobile;
