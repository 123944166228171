import { VideoCameraIcon } from '@heroicons/react/outline';
import React, { ChangeEvent, useContext, useRef, useState } from 'react';
import { v4 } from 'uuid';
import uploadService from '../services/upload.service';
import { MeContext } from './contexts/me.context';
import { FieldErrors } from './form/FieldErrors';
import Loading from './Loading';
import VideoPlayer from './VideoPlayer';

const getVideoDuration = async (f: any) => {
	const fileCallbackToPromise = (fileObj: any) => {
		return Promise.race([
			new Promise((resolve) => {
				if (fileObj instanceof HTMLImageElement) fileObj.onload = resolve;
				else fileObj.onloadedmetadata = resolve;
			}),
			new Promise((_, reject) => {
				setTimeout(reject, 1000);
			}),
		]);
	};

	const objectUrl = URL.createObjectURL(f);
	// const isVideo = type.startsWith('video/');
	const video = document.createElement('video');
	video.src = objectUrl;
	await fileCallbackToPromise(video);
	return {
		duration: video.duration,
		width: video.videoWidth,
		height: video.videoHeight,
	};
};

type Props = {
	label: string;
	name: string;
	value?: string;
	onChange: (val: string) => void;
	maxSize?: number;
	className?: string;
};

const VideoUploader: React.FC<Props> = ({
	label,
	name,
	value,
	onChange,
	className = '',
}) => {
	const { me } = useContext(MeContext);
	const input = useRef<HTMLInputElement>(null);
	const [loading, setLoading] = useState(false);
	const [videoError, setVideoError] = useState('');

	const onChangeFile = async (e: ChangeEvent<HTMLInputElement>) => {
		setVideoError('');
		const files = e.target.files as any;
		if (!files?.length) {
			return;
		}
		const file = files[0];
		try {
			setLoading(true);
			const theFile = await processVideo(file);
			const playbackId = await uploadService.upload(
				me?.account.id,
				theFile,
				file.type,
				true
			);

			onChange(playbackId);
			setLoading(false);
		} catch (err) {
			console.log(err);
			setLoading(false);
		}
	};

	const processVideo = async (file: File) => {
		// If video is longer than 90 seconds, throw an error
		const { duration } = await getVideoDuration(file);
		if (duration > 90) {
			setVideoError('Video must be less than 90 seconds');
			throw new Error('Video must be less than 90 seconds');
		}
		let filename = v4();

		Object.assign(file, {
			extension: file.name.split('.').pop(),
			filename,
		});
		return file;
	};

	return (
		<div className={className}>
			<label
				htmlFor={name}
				className="block text-sm font-medium leading-5 text-gray-900  sm:mt-px sm:pt-2"
			>
				{label}
			</label>
			<div
				className={[
					'mt-1 flex',
					value ? 'flex-col gap-4' : 'flex-row items-center gap-4',
				].join(' ')}
			>
				{value ? (
					<VideoPlayer playbackId={value} />
				) : (
					<span className="h-12 w-12 rounded-md overflow-hidden bg-gray-200">
						<VideoCameraIcon className="h-full w-full text-gray-400" />
					</span>
				)}
				<div className="flex gap-2">
					<button
						type="button"
						className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
						onClick={() => input.current?.click()}
					>
						Change
					</button>
					{value && (
						<button
							type="button"
							className="bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
							onClick={() => onChange('')}
						>
							Remove
						</button>
					)}
					{loading && <Loading size={8} color="text-gray-400" />}
				</div>
				<input
					ref={input}
					onChange={onChangeFile}
					multiple={false}
					type="file"
					hidden
				/>
			</div>
			{!!videoError && <FieldErrors errors={{ message: videoError }} />}
		</div>
	);
};

export default VideoUploader;
