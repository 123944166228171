import { Auth } from '@aws-amplify/auth';
import React, {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CompleteSetupForm } from '../../common/CompleteSetup';
import { useForm } from '../../common/hooks/useForm';
import { FormError } from '../../common/types/ErrorResponse.type';
import logo from '../../images/sfs-black-logo.png';
import * as accountService from '../../services/account.service';
import { SubOptions } from '../accounts/Account.type';
import { Option } from '../billing/subscription-checkout/SubscriptionCheckout';
import SignUpFullAccount from './SignUpFullAccount';
import SignUpPlan from './SignUpPlan';
import SignUpPlanInformation from './SignUpPlanInformation';
import SignUpSteps from './SignUpSteps';
import { ToastContext, ToastTypes } from '../../common/contexts/toast.context';

type Props = {
	onAuth: Dispatch<SetStateAction<object | undefined>>;
};

const fullAccountValidation = {
	name: {
		required: {
			message: 'Company name is required.',
		},
		pattern: {
			value: new RegExp(/^[a-zA-Z0-9\s]*$/),
			message: 'Cannot contain special characters',
		},
	},
	phone: {
		required: {
			message: 'Phone is required.',
		},
		pattern: {
			value: new RegExp(/^(\+\d{1,2}\s?)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/),
			message: 'Provide a valid phone number',
		},
	},
	email: {
		required: {
			message: 'Email is required.',
		},
	},
	password: {
		required: {
			message:
				'Password required needs the following: At least 8 characters, upper case, lower case, number, and special character',
		},
		pattern: {
			value:
				/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*(),.?":{}|<>])[A-Za-z\d!@#$%^&*(),.?":{}|<>]{8,}$/,
			message:
				'Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.',
		},
	},
	address: {
		required: {
			message: 'Address is required.',
		},
	},
	city: {
		required: {
			message: 'City is required.',
		},
	},
	state: {
		required: {
			message: 'State is required.',
		},
	},
	zip: {
		required: {
			message: 'Zip is required.',
		},
	},
	manufacturer: {
		function: {
			value: (val: Partial<CompleteSetupForm>) => {
				if (val.isManufacturer && !val.manufacturer) {
					return 'Please select your company in the list above.';
				}
				return false;
			},
		},
	},
};

const options: Option[] = [
	{
		title: 'Unlimited listings',
		subtitle: 'Up to 5 listings',
		val: 'unlimitedListings',
		descr: 'Unlock unlimited additional product listings.',
		price: 50,
		recurrence: 'mo',
		basic: true,
	},
	{
		title: 'Additional locations',
		subtitle: '1 location',
		val: 'additionalLocations',
		descr: 'Perfect if you manage multiple sites.',
		price: 10,
		recurrence: 'mo (per location)',
		priceSubtitle: 'per location',
		count: 1,
	},
	{
		title: 'Unlimited images',
		subtitle: '1 image per listing for free.',
		val: 'unlimitedImages',
		descr:
			"Help buyers know what they're getting by showing more of your sheds.",
		price: 10,
		recurrence: 'mo',
		basic: true,
	},
	{
		title: 'Click analytics',
		val: 'clickAnalytics',
		descr:
			'Curious how your inventory is doing? Click analytics show information on how many times your products are being viewed.',
		price: 5,
		recurrence: 'mo',
	},
	{
		title: 'Dealer direct privacy',
		val: 'dealerDirectPrivacy',
		descr:
			'Hide competing inventory on your Dealer Direct Page. Only yours will be visible.',
		price: 10,
		recurrence: 'mo',
		basic: true,
	},
	{
		title: 'API access',
		val: 'apiAccess',
		descr:
			'Connect your inventory instantly with popular platforms like Cal and Shed Suite.',
		price: 25,
		recurrence: 'mo',
	},
	{
		title: 'Video',
		val: 'video',
		descr:
			'Offering video of your inventory will help to increase customer engagement and sales.',
		price: 10,
		recurrence: 'mo',
	},
	{
		title: 'Text notifications',
		val: 'smsNotifications',
		descr:
			'Add the ability to receive text messages when customers submit a contact form on your product pages. (Price is based on $5/mo per active location.)',
		price: 5,
		recurrence: 'mo',
		count: 1,
		basic: true,
	},
];

const SignUp: React.FC<Props> = ({ onAuth }) => {
	const navigate = useNavigate();
	const { createToast } = useContext(ToastContext);
	const {
		value: subValue,
		onChange: onChangeSub,
		patchValue: patchValueSub,
		isDirty: isDirtySub,
	} = useForm<SubOptions>({
		additionalLocations: 0,
		unlimitedListings: false,
		unlimitedImages: false,
		clickAnalytics: false,
		dealerDirectPrivacy: false,
		apiAccess: false,
		smsNotifications: 0,
	});
	const {
		value: fullAccountValue,
		onChange: onChangeFullAccount,
		registerSubmit: registerSubmitFullAccount,
		isDirty: isDirtyFullAccount,
		isSubmitting: isSubmittingFullAccount,
		errors: fullAccountErrors,
	} = useForm<CompleteSetupForm>(
		{
			name: '',
			phone: '',
			cellPhone: '',
			email: '',
			password: '',
			address: '',
			city: '',
			state: '',
			zip: '',
			manufacturers: [],
			isManufacturer: false,
			manufacturer: 0,
		},
		fullAccountValidation
	);
	const [additionalLocationsActive, setAdditionalLocationsActive] =
		useState<boolean>(false);
	const [steps, setSteps] = useState([
		{ name: '1. BUILD YOUR PLAN', status: 'current', id: 'plan' },
		{ name: '2. ACCOUNT INFORMATION AND PAYMENT', status: '', id: 'payment' },
	]);
	const [basicPackage, setBasicPackage] = useState<boolean>(false);

	useEffect(() => {
		if (
			subValue.additionalLocations ||
			(0 >= 1 && !additionalLocationsActive)
		) {
			setAdditionalLocationsActive(true);
		}
	}, [additionalLocationsActive, subValue]);

	const totalPrice = useMemo(() => {
		let total = 0;
		options.forEach((opt) => {
			if (opt.val === 'additionalLocations') {
				total += (subValue.additionalLocations || 0) * 10;
			} else if (opt.val === 'smsNotifications') {
				total += (subValue.smsNotifications || 0) * 5;
			} else {
				if (subValue[opt.val as keyof SubOptions]) {
					total += opt.price;
				}
			}
		});
		return total;
	}, [subValue]);

	const onSubmitFullAccount = async (val: any) => {
		if (!fullAccountValue.email || !fullAccountValue.password) {
			return;
		}
		const resSignUp = await accountService.createSubscriptionAndSignUp({
			email: fullAccountValue.email,
			password: fullAccountValue.password,
			subOptions: subValue,
			account: fullAccountValue,
		});

		return resSignUp;
	};

	const onFail = async (err: any) => {
		createToast({
			type: ToastTypes.Fail,
			title: 'Failed to Sign Up',
			description: (
				<>
					{err?.response?.data?.message
						? err?.response?.data?.message
						: 'Unable to sign up, please try again'}
				</>
			),
		});
	};

	const moveStep = (step: number) => {
		const realStep = step - 1;
		setSteps((prev: any) => {
			return prev.map((st: any, i: number) => {
				return i === realStep
					? { ...prev[i], status: 'current' }
					: i === realStep - 1
					? { ...prev[i], status: 'complete' }
					: { ...prev[i], status: 'complete' };
			});
		});
	};

	const onSuccess = async (res: any) => {
		createToast({
			type: ToastTypes.Success,
			title: 'Successfully Signed Up!',
			description: <>Please wait while we log you in</>,
		});
		const resSignIn = await Auth.signIn(
			fullAccountValue.email as string,
			fullAccountValue.password
		);
		onAuth(resSignIn);
		if (res?.url) {
			window.location.href = res.url;
		} else {
			return navigate('/') as any;
		}
	};

	const confirmSignUp = async () => {
		return registerSubmitFullAccount(onSubmitFullAccount, {
			onSuccess,
			onFail,
		})();
	};

	const updatePackage = () => {
		if (!basicPackage) {
			patchValueSub({
				...subValue,
				unlimitedListings: true,
				unlimitedImages: true,
				smsNotifications: 1,
				dealerDirectPrivacy: true,
			});
			setBasicPackage(true);
		} else {
			patchValueSub({
				...subValue,
				unlimitedListings: false,
				unlimitedImages: false,
				dealerDirectPrivacy: false,
				smsNotifications: 0,
			});
			setBasicPackage(false);
			setAdditionalLocationsActive(false);
		}
	};
	return (
		<>
			<div className="w-full h-24 bg-primary-500 flex justify-center items-center">
				<Link to="/" className="">
					<img
						src={logo}
						alt="Shedsforsale.com logo"
						className="object-scale-down h-12"
					/>
				</Link>
			</div>
			<div className="min-h-screen max-w-6xl w-full relative mx-auto flex flex-col items-start justify-start py-2 sm:px-6 lg:px-8 pt-4 md:pt-24 sm:gap-12">
				<div className="px-4 sm:px-0 w-full">
					<SignUpSteps steps={steps} setSteps={setSteps} />
				</div>
				{steps[0].status === 'current' ? (
					<div className="w-full flex justify-between gap-12">
						<SignUpPlan
							options={options}
							setAdditionalLocationsActive={setAdditionalLocationsActive}
							patchValue={patchValueSub}
							value={subValue}
							onChange={onChangeSub}
							additionalLocationsActive={additionalLocationsActive}
							totalPrice={totalPrice}
							moveStep={moveStep}
							isDirty={isDirtySub}
							basicPackage={basicPackage}
							setBasicPackage={updatePackage}
						/>
						<SignUpPlanInformation
							subValue={subValue}
							options={options}
							totalPrice={totalPrice}
						/>
					</div>
				) : (
					<>
						<div className="w-full flex justify-between gap-12">
							<SignUpFullAccount
								value={fullAccountValue}
								onChange={onChangeFullAccount}
								errors={fullAccountErrors}
								registerSubmit={registerSubmitFullAccount}
								confirmSignUp={confirmSignUp}
								isSubmitting={isSubmittingFullAccount}
								isDirty={isDirtyFullAccount}
							/>
							<SignUpPlanInformation
								subValue={subValue}
								options={options}
								totalPrice={totalPrice}
							/>
						</div>
					</>
				)}
				<div className="h-36 sm:h-24"></div>
			</div>
		</>
	);
};

export default SignUp;
