import copy from 'copy-to-clipboard';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonColors } from '../../../common/Button';
import {
	ToastContext,
	ToastTypes,
} from '../../../common/contexts/toast.context';
import { Input } from '../../../common/form/Input';
import { Phone } from '../../../common/form/Phone';
import { Select } from '../../../common/form/Select';
import { Textarea } from '../../../common/form/Textarea';
import { useForm } from '../../../common/hooks/useForm';
import ManufacturerAutocomplete from '../../../common/ManufacturerAutocomplete';
import SingleImageUploader from '../../../common/SingleImageUploader';
import { STATES } from '../../../constants';
import { Account } from '../Account.type';
import * as userService from '../../../services/user.service';
import ConfirmModal from '../../../common/ConfirmModal';
import { OpenClosedStates } from '../../../common/OpenClosedStates';
import { MeContext } from '../../../common/contexts/me.context';
import * as accountService from '../../../services/account.service';

const validation = {
	name: {
		required: {
			message: 'Name is required.',
		},
	},
};

type Props = {
	defaultValues?: Partial<any>;
	save: (val: Partial<Account>) => Promise<Account | undefined>;
};

const AccountGeneral: React.FC<Props> = ({ defaultValues, save }) => {
	const { me } = useContext(MeContext);
	const { createToast } = useContext(ToastContext);
	const navigate = useNavigate();
	const {
		value,
		isDirty,
		errors,
		onChange,
		registerSubmit,
		isSubmitting,
		patchValue,
	} = useForm<any>(defaultValues || {}, validation);
	const [resettingPassword, setResettingPassword] = useState<boolean>(false);
	const [confirmDeleteModal, setConfirmDeleteModal] =
		useState<OpenClosedStates>(OpenClosedStates.Closed);
	const [deletingAccount, setDeletingAccount] = useState<boolean>(false);

	useEffect(() => {
		if (defaultValues) {
			patchValue(defaultValues);
		}
	}, [patchValue, defaultValues]);

	const onSuccess = () => {
		createToast({
			title: 'Success!',
			description: <>Successfully saved account!</>,
			type: ToastTypes.Success,
			duration: 5000,
		});
		if (!value.id) {
			navigate('/accounts');
		}
	};

	const onFail = (err: any) => {
		const description = (
			<>{err?.response?.data || err?.response || 'Failed to save account!'}</>
		);
		createToast({
			title: 'Error!',
			description,
			type: ToastTypes.Fail,
			duration: 5000,
		});
	};

	const onSubmit = async (account: Partial<any>) => {
		return save(account);
	};

	const onClickCancel = () => {
		navigate(-1);
	};

	const onClickResetPassword = async () => {
		setResettingPassword(true);
		if (!defaultValues?.email) {
			return;
		}
		try {
			await userService.forgotPassword(defaultValues.email);
		} catch (err) {
			setResettingPassword(false);
			createToast({
				type: ToastTypes.Fail,
				title: 'Failed to reset user password',
				description: <span></span>,
				duration: 5000,
			});
		}
		setResettingPassword(false);
		createToast({
			type: ToastTypes.Success,
			title: 'Successfully reset user password',
			description: <span></span>,
			duration: 5000,
		});
	};

	const deleteAccount = async () => {
		if (!defaultValues?.id || me?.account?.id !== 0) {
			return;
		}
		setConfirmDeleteModal(OpenClosedStates.Closed);
		setDeletingAccount(true);

		try {
			await accountService.deleteAccount(defaultValues?.id);
		} catch (err) {
			createToast({
				title: 'Failure!',
				description: <>Failed to delete account!</>,
				type: ToastTypes.Fail,
				duration: 5000,
			});
			setDeletingAccount(false);
			return;
		}

		setDeletingAccount(false);
		createToast({
			title: 'Success!',
			description: <>Successfully deleted account!</>,
			type: ToastTypes.Success,
			duration: 5000,
		});
		navigate('/accounts');
	};

	return (
		<>
			<div className="grid grid-cols-1 sm:grid-cols-6 gap-4 ml-6 mt-6 mr-5">
				<div className="bg-gray-200 sm:rounded-lg col-span-6">
					<div className="px-4 py-5 sm:p-6">
						<h3 className="text-lg leading-6 font-medium text-gray-900">
							Share your listings
						</h3>
						<div className="mt-2 max-w-xl text-sm text-gray-500">
							<p>
								As a subscriber to ShedsForSale.com, you have access to your own
								personal listing page (URL part can be edited below).
							</p>
						</div>
						<div className="mt-5">
							<button
								type="button"
								className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:text-sm"
								onClick={() =>
									copy(
										`${process.env.REACT_APP_CUSTOMER_URL}/dealers/${value.path}`
									)
								}
							>
								Copy URL
							</button>
						</div>
					</div>
				</div>
				<Input
					name="name"
					value={value.name}
					errors={errors.fieldErrors?.name}
					onChange={onChange('name')}
					className="col-span-6"
					label="Company name *"
				/>
				<Input
					name="website"
					value={value.website}
					errors={errors.fieldErrors?.website}
					onChange={onChange('website')}
					className="col-span-6"
					label="Website"
				/>
				<Phone
					name="phone"
					value={value.phone}
					errors={errors.fieldErrors?.phone}
					onChange={onChange('phone')}
					className="col-span-6 sm:col-span-3"
					label="Phone *"
				/>
				<Input
					label="Email *"
					value={value.email}
					onChange={onChange('email')}
					name="email"
					className="col-span-6 sm:col-span-3"
					errors={errors.fieldErrors?.email}
				/>
				<Input
					label="Address *"
					value={value.address}
					onChange={onChange('address')}
					name="address"
					className="col-span-6"
					errors={errors.fieldErrors?.address}
				/>
				<Input
					label="City *"
					value={value.city}
					onChange={onChange('city')}
					name="city"
					className="col-span-6 sm:col-span-3"
					errors={errors.fieldErrors?.city}
				/>
				<Select
					label="State *"
					value={value.state}
					onChange={onChange('state')}
					name="state"
					className="col-span-6 sm:col-span-2"
					errors={errors.fieldErrors?.state}
					options={STATES}
					limit={1}
				/>
				<Input
					label="Zip *"
					value={value.zip}
					onChange={onChange('zip')}
					name="zip"
					className="col-span-6 sm:col-span-1"
					errors={errors.fieldErrors?.zip}
				/>
				<Input
					name="path"
					className="col-span-6"
					label="URL part *"
					value={value.path}
					onChange={onChange('path')}
					errors={errors.fieldErrors?.path}
					hint={
						<>
							Your products will be listed at{' '}
							{value.path && (
								<a
									href={`${process.env.REACT_APP_CUSTOMER_URL}/dealers/${value.path}`}
									target="_blank"
									rel="noreferrer"
									className="text-primary-500 hover:text-primary-600"
								>
									{process.env.REACT_APP_CUSTOMER_URL}/dealers/{value.path}
								</a>
							)}
							.
						</>
					}
					onBlur={(val) =>
						onChange('path')(val?.toLowerCase()?.replace(/ /g, '-'))
					}
				/>
				<ManufacturerAutocomplete
					className="col-span-6 sm:col-span-3"
					value={value.manufacturers ?? []}
					onChange={onChange('manufacturers')}
					errors={errors.fieldErrors?.manufacturers}
					label="Manufacturers available"
				/>
				<Input
					name="freeDeliveryRange"
					className="col-span-6 sm:col-span-3"
					label="Free delivery range (in miles)"
					value={value.freeDeliveryRange}
					onChange={onChange('freeDeliveryRange')}
					errors={errors.fieldErrors?.freeDeliveryRange}
					type="number"
					min="0"
					max="24902"
				/>
				<Textarea
					name="deliveryInstructions"
					className="col-span-6"
					label="Delivery instructions"
					value={value.deliveryInstructions}
					onChange={onChange('deliveryInstructions')}
					errors={errors.fieldErrors?.deliveryInstructions}
				/>
				<Textarea
					name="warranty"
					className="col-span-6"
					label="Warranty"
					value={value.warranty}
					onChange={onChange('warranty')}
					errors={errors.fieldErrors?.warranty}
				/>
				<SingleImageUploader
					className="col-span-6"
					label="Logo"
					name="logo"
					value={value.logo}
					onChange={onChange('logo')}
				/>
				{value.plan !== 'essentials' && (
					<SingleImageUploader
						className="col-span-6"
						label="Dealer page header"
						name="header"
						value={value.header}
						onChange={onChange('header')}
						maxSize={2200}
					/>
				)}
			</div>

			<div className="w-full flex justify-end mt-8">
				<div className="flex space-x-4 justify-end mr-5">
					<Button
						text="Cancel"
						onClick={onClickCancel}
						color={ButtonColors.transparent}
					/>
					<Button
						text={!!value.id ? 'Save' : 'Create'}
						onClick={registerSubmit(onSubmit, { onSuccess, onFail })}
						color={ButtonColors.primary}
						loading={isSubmitting}
						disabled={!isDirty}
					/>
					<Button
						text="Reset password"
						color={ButtonColors.red}
						onClick={onClickResetPassword}
						disabled={resettingPassword}
						loading={resettingPassword}
					/>
					{me?.account?.id === 0 && (
						<Button
							text="Delete account"
							color={ButtonColors.red}
							onClick={() => setConfirmDeleteModal(OpenClosedStates.Open)}
							disabled={deletingAccount || me?.account?.id !== 0}
							loading={deletingAccount}
						/>
					)}
				</div>
			</div>
			<ConfirmModal
				state={confirmDeleteModal}
				close={() => setConfirmDeleteModal(OpenClosedStates.Closed)}
				confirm={() => deleteAccount()}
				title="Delete this account?"
				description="Deleting this account will remove all locations, products, product views, users, notifications, and any other metadata associated with this account. This operation is irreparable. If you have confirmed that the account holder wishes to proceed under these conditions, click confirm below to delete the account."
			/>
		</>
	);
};

export default AccountGeneral;
