import { Disclosure } from '@headlessui/react';
import {
	ColorSwatchIcon,
	MinusSmIcon,
	PlusSmIcon,
	StarIcon,
} from '@heroicons/react/outline';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button, { ButtonColors } from '../../../common/Button';
import ConfirmModal from '../../../common/ConfirmModal';
import { MeContext } from '../../../common/contexts/me.context';
import {
	ToastContext,
	ToastTypes,
} from '../../../common/contexts/toast.context';
import { Checkbox } from '../../../common/form/Checkbox';
import { Input } from '../../../common/form/Input';
import { Select } from '../../../common/form/Select';
import { Textarea } from '../../../common/form/Textarea';
import { useForm } from '../../../common/hooks/useForm';
import ImageUploader from '../../../common/ImageUploader';
import Modal, { ModalSizes } from '../../../common/Modal';
import { OpenClosedStates } from '../../../common/OpenClosedStates';
import VideoUploader from '../../../common/VideoUploader';
import {
	COLORS_MAIN,
	COLORS_METAL,
	COLORS_PAINT,
	COLORS_ROOF,
	PRODUCT_TYPES,
	ROOFING_TYPES,
	ROOF_STYLES,
	SIDING_TYPES,
	STYLES,
} from '../../../constants';
import useAccountSettings from '../../account-settings/useAccountSettings';
import useManufacturerColors from '../../manufacturers/hooks/useManufactureColors';
import { Product, ProductImage } from '../Product.type';
import ManufacturerColorModal from './ManufacturerColorModal';
import SponsoredListingModal from './SponsoredListingModal';
import { DESCRIPTION_OPTIONS, DescriptionOption } from '../../../constants';

const generatepath = (manufacturerName: string = '', title: string = '') => {
	return encodeURIComponent(
		`${manufacturerName}${manufacturerName ? '-' : ''}${title}`
			.toLowerCase()
			.replace(/ /g, '-')
			.replace(/--/g, '-')
			.replace(/[^a-z-]/g, '')
	);
};

const validation = {
	title: {
		required: {
			message: 'Name is required.',
		},
	},
	model: {
		required: {
			message: 'Model is required.',
		},
	},
	price: {
		required: {
			message: 'Price is required.',
		},
	},
	reducedPrice: {
		function: {
			value: (val: Partial<Product>) => {
				if ((val.reducedPrice || 0) >= (val.price || 0)) {
					return 'Reduced price must be lower than original price.';
				}
				return false;
			},
		},
	},
	type: {
		required: {
			message: 'Type is required.',
		},
	},
	style: {
		required: {
			message: 'Style is required.',
		},
	},
	roofStyle: {
		required: {
			message: 'Roof style is required.',
		},
	},
	manufacturer: {
		required: {
			message: 'Manufacturer is required.',
		},
	},
	description: {
		required: {
			message: 'Description is required.',
		},
	},
	location: {
		required: {
			message: 'Location is required.',
		},
	},
	width: {
		required: {
			message: 'Width is required.',
		},
	},
	length: {
		required: {
			message: 'Length is required.',
		},
	},
	freeDeliveryRange: {
		function: {
			value: (val: Partial<Product>) => {
				if (val.freeDeliveryRange) {
					if (val.freeDeliveryRange < 0) {
						return 'Please select a delivery range of at least 0 miles.';
					}
					if (val.freeDeliveryRange > 24902)
						return 'Please select a delivery range of no more than the circumference of earth. Let us know if you plan on offering free delivery to other planets and we will do our best to accomodate you.';
				}
				return false;
			},
		},
	},
	externalUrl: {
		function: {
			value: (val: Partial<Product>) => {
				if (val.externalUrl) {
					let isInternal = val.externalUrl
						.toLowerCase()
						.includes('shedsforsale');
					if (isInternal) {
						return 'Please enter an external link. Example: yourshedwebsite.com';
					}
				}
				return false;
			},
		},
		pattern: {
			value: new RegExp(
				/^(https?:\/\/)?([a-z0-9-]+\.)*[a-z0-9-]+\.[a-z]+(\/[a-z0-9-._~:?#%[\]@!$&'()*+,;=]*)*$/i
			),
			message:
				'Please provide a valid link. The link must point to a separate, external website. Example: yourshedwebsite.com',
		},
	},
};

type Props = {
	defaultValues?: Partial<Product>;
	save: (val: Partial<Product>) => Promise<Product | undefined>;
	del: (sold: boolean) => Promise<void>;
	mark: (val: boolean) => Promise<boolean>;
	clone: () => Promise<void>;
	isDeleting: boolean;
	isMarking: boolean;
	isCloning: boolean;
};

const ProductGeneral: React.FC<Props> = ({
	defaultValues,
	save,
	del,
	mark,
	clone,
	isDeleting,
	isMarking,
	isCloning,
}) => {
	const { me } = useContext(MeContext);
	const { createToast } = useContext(ToastContext);
	const [deleteModal, setDeleteModal] = useState(OpenClosedStates.Closed);
	const [soldModal, setSoldModal] = useState(OpenClosedStates.Closed);
	const navigate = useNavigate();
	const {
		value,
		isDirty,
		errors,
		onChange,
		registerSubmit,
		isSubmitting,
		patchValue,
	} = useForm<Product>(defaultValues || {}, validation);
	const { data } = useAccountSettings() as any;
	const { data: manufacturerColors, onChangeManufacturer } =
		useManufacturerColors(defaultValues?.manufacturer);
	const [manufacturerColorsComplete, setManufacturerColorsComplete] =
		useState<any>();
	const [manufacturerColorModal, setManufacturerColorModal] =
		useState<OpenClosedStates>(OpenClosedStates.Closed);
	const [currentKey, setCurrentKey] = useState<keyof Product>();
	const [sponsoredListingModalState, setSponsoredListingModalState] =
		useState<OpenClosedStates>(OpenClosedStates.Closed);

	useEffect(() => {
		console.log({ value });
	}, [value]);

	useEffect(() => {
		if (defaultValues) {
			patchValue(defaultValues);
		}
	}, [patchValue, defaultValues]);

	useEffect(() => {
		const colorOptions = manufacturerColors?.data?.map((color: any) => ({
			primaryColor: color.primaryColor,
			specificColor: color.specificColor,
			id: color.ID,
		}));
		setManufacturerColorsComplete(colorOptions);
	}, [manufacturerColors]);

	useEffect(() => {
		if (
			!defaultValues?.manufacturer &&
			me?.account?.manufacturers.length === 1
		) {
			patchValue({ manufacturer: me?.account?.manufacturers[0].value });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [patchValue, defaultValues?.manufacturer]);

	useEffect(() => {
		if (!value.model || value.model === '') {
			return;
		}
		if (defaultValues?.title) {
			return;
		}

		onChange('title')(
			value.width && value.length && value.model
				? `${value.width} x ${value.length}${' '}${value.model}`
				: `${value.title}`
		);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value.width, value.length, value.model, defaultValues]);

	useEffect(() => {
		const { manufacturers, locations } = data.account;

		if (
			!value.model ||
			value.model === '' ||
			!value.width ||
			!value.length ||
			!value.type ||
			!value.style ||
			!value.manufacturer ||
			!value.location
		) {
			return;
		}

		if (defaultValues?.description) {
			return;
		}

		const selectedManufacturer = manufacturers.find(
			(manufacturer: any) => manufacturer.value === value.manufacturer
		);
		const manufacturerName = selectedManufacturer
			? selectedManufacturer.text
			: '';

		const locationOptions = locations
			.filter((location: any) => !!location.active)
			.map((location: any) => ({
				text: `${location.city}, ${location.state} (${location.address})`,
				value: location.id,
			}));

		const selectedLocationOption = locationOptions.find(
			(locationOption: any) => locationOption.value === value.location
		);
		const locationName = selectedLocationOption
			? selectedLocationOption.text
			: '';

		const randomDescriptionOption: DescriptionOption =
			DESCRIPTION_OPTIONS[
			Math.floor(Math.random() * DESCRIPTION_OPTIONS.length)
			];

		const descriptionText = randomDescriptionOption.description(
			value.width,
			value.length,
			value.model?.trim(),
			value.type,
			value.style,
			manufacturerName?.trim(),
			locationName?.trim()
		);

		onChange('description')(descriptionText);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		value.width,
		value.length,
		value.model,
		value.type,
		value.style,
		value.manufacturer,
		value.location,
		data.account.manufacturers,
		data.account.locations,
		defaultValues,
	]);

	const onSuccess = () => {
		createToast({
			title: 'Success!',
			description: <>Successfully saved product!</>,
			type: ToastTypes.Success,
			duration: 5000,
		});
		if (!value.id) {
			navigate('/products?page=1');
		}
	};

	const onFail = (res: any) => {
		createToast({
			title: 'Failure!',
			description: (
				<>{res?.response?.data?.message || 'Failed to save product!'}</>
			),
			type: ToastTypes.Fail,
			duration: 5000,
		});
		document
			.getElementById('productTop')
			?.scrollIntoView({ behavior: 'smooth' });
	};

	const onSubmit = async (product: Partial<Product>) => {
		if (product.externalUrl) {
			let urlStart = product.externalUrl.split(':')[0].toLowerCase();
			if (urlStart === 'http' || urlStart === 'https') {
				return save(product);
			} else {
				product.externalUrl = `https://${product.externalUrl}`;
				return save(product);
			}
		} else {
			return save(product);
		}
	};

	const onClickCancel = () => {
		navigate(-1);
	};

	const onChangeImages = (val: ProductImage[]) => {
		patchValue({ images: val });
	};

	const prefix = useMemo(() => {
		const name =
			me.account?.manufacturers?.find((man) => man.value === value.manufacturer)
				?.text || '';
		return generatepath(name, value.title).substr(0, 200);
	}, [value.manufacturer, value.title, me.account]);

	return (
		<>
			{value.sponsoredListing?.active ? (
				<div className="mb-4">
					<div className="rounded-md bg-primary-100 shadow-sm p-4 w-full">
						<div className="flex">
							<div className="flex-shrink-0">
								<StarIcon
									className="h-5 w-5 text-yellow-400"
									aria-hidden="true"
								/>
							</div>

							<div className="ml-3">
								<h3 className="text-sm font-medium text-yellow-800">
									Featured listing active
								</h3>
								<div className="mt-2 text-sm text-yellow-700 flex flex-col gap-2">
									<p>
										This product is currently appearing at the top of search
										results.
									</p>
									<p className="font-medium">
										Start date:{' '}
										{dayjs(value?.sponsoredListing?.createDate).format(
											'MM/DD/YYYY'
										)}
									</p>
									<p className="font-medium">
										Ends:{' '}
										{dayjs(value?.sponsoredListing?.endDate).format(
											'MM/DD/YYYY'
										)}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				defaultValues?.id && (
					<div className="rounded-md bg-primary-100 shadow-sm">
						<div className="h-full flex flex-col w-full ">
							<div className="flex flex-col items-center gap-2 p-8w-full">
								<StarIcon className="w-7 h-7 text-primary-500 mt-2" />
								<h2 className="text-base font-semibold text-primary-600">
									Feature this shed!
								</h2>
								<p className="text-xl font-extrabold text-gray-900">
									Featured sheds appear at the top of product listings.
								</p>
								<p className="max-w-xl mx-auto text-gray-500">
									Simply choose a length of time - 1 week, 2 weeks, or 1 month,
									and this shed will begin appearing at the top of search
									results immediately!
								</p>
								<span className="w-2/3 flex justify-center items-center gap-2 text-lg text-primary-600 mb-2">
									Ready to get started?
									<span
										className="text-primary-400 hover:text-primary-500 font-bold cursor-pointer"
										onClick={() =>
											setSponsoredListingModalState(OpenClosedStates.Open)
										}
									>
										Feature now &gt;
									</span>
								</span>
							</div>
						</div>
					</div>
				)
			)}

			<div className="grid grid-cols-1 sm:grid-cols-6 gap-4" id="productTop">
				<Input
					name="width"
					className="col-span-6 sm:col-span-1"
					label="Width *"
					value={value.width}
					onChange={onChange('width')}
					errors={errors?.fieldErrors?.width}
					type="number"
				/>
				<Input
					name="length"
					className="col-span-6 sm:col-span-1"
					label="Length *"
					value={value.length}
					onChange={onChange('length')}
					errors={errors?.fieldErrors?.length}
					type="number"
				/>
				<Input
					name="model *"
					className="col-span-6 sm:col-span-4"
					label="Model *"
					placeholder="Example: Garden Shed"
					value={value.model}
					onChange={onChange('model')}
					errors={errors?.fieldErrors?.model}
				/>
				<Input
					name="sku"
					className="col-span-6 sm:col-span-1"
					label="Serial #"
					value={value.sku}
					onChange={onChange('sku')}
					errors={errors?.fieldErrors?.sku}
				/>
				<Select
					name="type"
					className="col-span-6 sm:col-span-2"
					label="Type *"
					value={value.type}
					onChange={onChange('type')}
					errors={errors?.fieldErrors?.type}
					options={PRODUCT_TYPES}
					limit={1}
				/>
				<Select
					name="style"
					className="col-span-6 sm:col-span-3"
					label="Style *"
					value={value.style}
					onChange={onChange('style')}
					errors={errors?.fieldErrors?.style}
					options={STYLES}
					limit={2}
					hint="Select up to 2"
				/>
				<Input
					name="price"
					className="col-span-6 sm:col-span-3"
					label="Price *"
					value={value.price}
					onChange={onChange('price')}
					errors={errors?.fieldErrors?.price}
					type="number"
				/>
				<Input
					name="reducedPrice"
					className="col-span-6 sm:col-span-3"
					label="Reduced price"
					value={value.reducedPrice}
					onChange={onChange('reducedPrice')}
					errors={errors?.fieldErrors?.reducedPrice}
					type="number"
				/>
				<Input
					name="monthlyPayment"
					className="col-span-6 sm:col-span-3"
					label="Monthly payment"
					value={value.monthlyPayment}
					onChange={onChange('monthlyPayment')}
					errors={errors?.fieldErrors?.monthlyPayment}
					type="number"
				/>
				<Select
					name="roofStyle"
					className="col-span-6 sm:col-span-3"
					label="Roof style *"
					value={value.roofStyle}
					onChange={onChange('roofStyle')}
					errors={errors?.fieldErrors?.roofStyle}
					options={ROOF_STYLES}
					limit={1}
				/>
				<Select
					options={
						data?.account?.locations
							?.filter((location: any) => !!location.active)
							?.map((location: any) => ({
								text: `${location.city}, ${location.state} (${location.address})`,
								value: location.id,
							})) || []
					}
					label="Location *"
					value={value.location}
					onChange={onChange('location')}
					name="location"
					className="col-span-6 sm:col-span-3"
					errors={errors.fieldErrors?.location}
					limit={1}
				/>
				<Select
					options={me.account.manufacturers}
					label="Manufacturer *"
					value={value.manufacturer}
					onChange={(val: string) => {
						onChange('manufacturer')(val);
						onChangeManufacturer(val);
					}}
					name="manufacturer"
					className="col-span-6 sm:col-span-3"
					errors={errors.fieldErrors?.manufacturer}
					limit={1}
				/>
				<Input
					name="title"
					className="col-span-6 sm:col-span-6"
					label="Product Title *"
					value={value.title}
					onChange={onChange('title')}
					errors={errors?.fieldErrors?.title}
				/>
				<Textarea
					name="description"
					className="col-span-6"
					label="Description *"
					value={value.description}
					placeholder="Example: Utility Shed Perfect for your backyard storage needs! Gray Siding, White Trim, Galvalume Roof. Side Lofted Barn with 70in double doors and two windows."
					onChange={onChange('description')}
					errors={errors?.fieldErrors?.description}
				/>
				<Checkbox
					name="active"
					className="col-span-6 "
					label="Active"
					value={value.active}
					onChange={onChange('active')}
					errors={errors?.fieldErrors?.active}
				/>
				<Checkbox
					name="pinned"
					className="col-span-6 "
					label="Pinned"
					value={value.pinned}
					onChange={onChange('pinned')}
					errors={errors?.fieldErrors?.pinned}
					hint="Pinned products show up at the top of your dealer page only."
				/>
				<div className=" col-span-6 "></div>
				{me?.account?.subscription?.options?.video && (
					<VideoUploader
						className="col-span-6"
						label="Video"
						name="video"
						value={value.video}
						onChange={onChange('video')}
					/>
				)}
				<ImageUploader
					value={value.images || []}
					onChange={onChangeImages}
					prefix={prefix}
					errors={errors?.fieldErrors?.images}
					disabled={
						(value?.images?.length || 0 > 1) &&
							!me.account.subscription?.options?.unlimitedImages
							? true
							: false
					}
					createToast={(message: string, type: ToastTypes) =>
						createToast({
							title: 'Error!',
							description: <>{message}</>,
							type,
							duration: 5000,
						})
					}
					product={value}
					dealerInfo={
						{
							name: me?.account?.name,
							locations: me?.account?.locations,
							path: me?.account?.path,
						} as any
					}
				/>

				<div className="col-span-6 pt-4">
					<div className="w-full p-2 mx-auto">
						<Disclosure>
							{({ open }) => (
								<>
									<div className="relative">
										<div
											className="absolute inset-0 flex items-center"
											aria-hidden="true"
										>
											<div className="w-full border-t border-gray-300" />
										</div>
										<div className="relative flex justify-center">
											<Disclosure.Button
												type="button"
												className="inline-flex items-center shadow-sm px-4 py-1.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
											>
												{open ? (
													<MinusSmIcon
														className="-ml-1.5 mr-1 h-5 w-5 text-gray-400"
														aria-hidden="true"
													/>
												) : (
													<PlusSmIcon
														className="-ml-1.5 mr-1 h-5 w-5 text-gray-400"
														aria-hidden="true"
													/>
												)}
												<span>Details</span>
											</Disclosure.Button>
										</div>
									</div>
									<Disclosure.Panel className="pt-4 pb-2 text-sm text-gray-500 grid grid-cols-1 sm:grid-cols-6 gap-4">
										<Select
											name="roofingType"
											className="col-span-6 sm:col-span-3"
											label="Roofing type"
											value={value.roofingType}
											onChange={(val) => {
												onChange('colorRoof')(undefined);
												onChange('roofingType')(val);
											}}
											errors={errors?.fieldErrors?.roofingType}
											options={ROOFING_TYPES}
											limit={1}
										/>
										<div className="col-span-6 sm:col-span-3 w-full flex gap-2 items-start">
											<Input
												name="colorRoofSpecific"
												className="w-1/2 justify-self-start"
												label="Specific roof color"
												value={value.colorRoofSpecific}
												onChange={onChange('colorRoofSpecific')}
												errors={errors?.fieldErrors?.colorRoofSpecific}
												icon={
													<ColorSwatchIcon
														className="h-5 w-5 cursor-pointer"
														onClick={() => {
															setCurrentKey('colorRoofSpecific');
															setManufacturerColorModal(OpenClosedStates.Open);
														}}
													/>
												}
												iconRight
												clickIcon
											/>
											<Select
												name="colorRoof"
												className="w-1/2"
												label="Roof color"
												value={value.colorRoof}
												onChange={onChange('colorRoof')}
												errors={errors?.fieldErrors?.colorRoof}
												options={COLORS_ROOF[value.roofingType || 'shingle']}
												limit={1}
											/>
										</div>
										<Select
											name="sidingType"
											className="col-span-6 sm:col-span-3"
											label="Siding type"
											value={value.sidingType}
											onChange={(val) => {
												onChange('colorMain')(undefined);
												onChange('sidingType')(val);
											}}
											errors={errors?.fieldErrors?.sidingType}
											options={SIDING_TYPES}
											limit={1}
										/>
										<div className="col-span-6 sm:col-span-3 flex gap-2 items-start">
											<Input
												name="colorMainSpecific"
												className="w-1/2 justify-self-start"
												label="Specific main color"
												value={value.colorMainSpecific}
												onChange={onChange('colorMainSpecific')}
												errors={errors?.fieldErrors?.colorMainSpecific}
												icon={
													<ColorSwatchIcon
														className="h-5 w-5 cursor-pointer"
														onClick={() => {
															setCurrentKey('colorMainSpecific');
															setManufacturerColorModal(OpenClosedStates.Open);
														}}
													/>
												}
												iconRight
												clickIcon
											/>
											<Select
												name="colorMain"
												className="w-1/2"
												label="Main color"
												value={value.colorMain}
												onChange={onChange('colorMain')}
												errors={errors?.fieldErrors?.colorMain}
												options={COLORS_MAIN[value.sidingType || 'painted']}
												limit={1}
												disabled={!value.sidingType}
											/>
										</div>
										<div className="col-span-6 sm:col-span-3 flex gap-2 items-start">
											<Input
												name="colorTrimSpecific"
												className="w-1/2 justify-self-start"
												label="Specific trim color"
												value={value.colorTrimSpecific}
												onChange={onChange('colorTrimSpecific')}
												errors={errors?.fieldErrors?.colorTrimSpecific}
												icon={
													<ColorSwatchIcon
														className="h-5 w-5 cursor-pointer"
														onClick={() => {
															setCurrentKey('colorTrimSpecific');
															setManufacturerColorModal(OpenClosedStates.Open);
														}}
													/>
												}
												iconRight
												clickIcon
											/>
											<Select
												name="colorTrim"
												className="w-1/2"
												label="Trim color"
												value={value.colorTrim}
												onChange={onChange('colorTrim')}
												errors={errors?.fieldErrors?.colorTrim}
												options={
													value.sidingType === 'metal'
														? COLORS_METAL
														: COLORS_PAINT
												}
												limit={1}
											/>
										</div>
										<Input
											name="doors"
											className="col-span-6 sm:col-span-3"
											label="# of Doors"
											value={value.doors}
											onChange={onChange('doors')}
											errors={errors?.fieldErrors?.doors}
											type="number"
										/>
										<Input
											name="windows"
											className="col-span-6 sm:col-span-3"
											label="# of Windows"
											value={value.windows}
											onChange={onChange('windows')}
											errors={errors?.fieldErrors?.windows}
											type="number"
										/>
										<Input
											name="freeDeliveryRange"
											className="col-span-6 sm:col-span-3"
											label="Free delivery range (in miles)"
											value={value.freeDeliveryRange}
											onChange={onChange('freeDeliveryRange')}
											errors={errors.fieldErrors?.freeDeliveryRange}
											type="number"
											min="0"
											max="24902"
										/>
										<Textarea
											name="deliveryInstructions"
											className="col-span-6"
											label="Delivery instructions"
											value={value.deliveryInstructions}
											onChange={onChange('deliveryInstructions')}
											errors={errors.fieldErrors?.deliveryInstructions}
										/>
										<Textarea
											name="warranty"
											className="col-span-6"
											label="Warranty"
											value={value.warranty}
											onChange={onChange('warranty')}
											errors={errors?.fieldErrors?.warranty}
										/>
										<div className="col-span-6 mt-4 font-medium text-gray-900 text-base">
											<h3>The miscellaneous items will be listed under "What's included with this shed" as bullet points</h3>
										</div>
										<Textarea
											name="specialFeatures"
											className="col-span-3"
											label="Miscellaneous"
											value={value.specialFeatures}
											onChange={onChange('specialFeatures')}
											errors={errors?.fieldErrors?.specialFeatures}
										/>
										<Textarea
											name="specialFeatures2"
											className="col-span-3"
											label="Miscellaneous"
											value={value.specialFeatures2}
											onChange={onChange('specialFeatures2')}
											errors={errors?.fieldErrors?.specialFeatures2}
										/>
										<Textarea
											name="specialFeatures3"
											className="col-span-3"
											label="Miscellaneous"
											value={value.specialFeatures3}
											onChange={onChange('specialFeatures3')}
											errors={errors?.fieldErrors?.specialFeatures3}
										/>
										<Textarea
											name="specialFeatures4"
											className="col-span-3"
											label="Miscellaneous"
											value={value.specialFeatures4}
											onChange={onChange('specialFeatures4')}
											errors={errors?.fieldErrors?.specialFeatures4}
										/>
										<Input
											name="externalUrl"
											className="col-span-6"
											label="External Purchase URL"
											value={value.externalUrl}
											onChange={onChange('externalUrl')}
											errors={errors?.fieldErrors?.externalUrl}
										/>
									</Disclosure.Panel>
								</>
							)}
						</Disclosure>
					</div>
				</div>
			</div>
			<div className="w-full flex justify-end mt-8">
				<div className="flex space-x-4 justify-end">
					<Button
						text="Cancel"
						onClick={onClickCancel}
						color={ButtonColors.transparent}
					/>
					{!!defaultValues?.id && (
						<>
							<a
								href={`${process.env.REACT_APP_CUSTOMER_URL}/products/${value.path}`}
								target="_blank"
								rel="noreferrer"
							>
								<Button
									text="View your listing"
									color={ButtonColors.transparent}
								/>
							</a>
							{me?.account?.subscription?.plan === 'elite' && (
								<Button
									text="Clone"
									onClick={clone}
									color={ButtonColors.plainWithBorder}
									loading={isCloning}
								/>
							)}
							<Button
								text={!!defaultValues.sold ? 'Mark for sale' : 'Mark as sold'}
								onClick={() => setSoldModal(OpenClosedStates.Open)}
								color={ButtonColors.plainWithBorder}
							/>
							<Button
								text="Delete"
								onClick={() => setDeleteModal(OpenClosedStates.Open)}
								color={ButtonColors.red}
							/>
						</>
					)}
					<Button
						text={!!value.id ? 'Save' : 'Create'}
						onClick={registerSubmit(onSubmit, { onSuccess, onFail })}
						color={ButtonColors.primary}
						loading={isSubmitting}
						disabled={!isDirty}
					/>
				</div>
			</div>
			<Modal state={deleteModal} size={ModalSizes['lg']} noScroll>
				<div>
					<div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-gray-100 dark:bg-darkGray-500">
						<svg
							className="h-6 w-6 text-gray-600 dark:text-gray-400"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth={2}
								d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
							/>
						</svg>
					</div>
					<div className="mt-3 text-center sm:mt-5">
						<h3
							className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100"
							id="modal-headline"
						>
							Delete product
						</h3>
						<div className="mt-6 text-left">
							<p className="text-sm leading-5 text-gray-500 dark:text-gray-400">
								Are you sure you want to delete this product? If so, please
								select the appropriate button to mark whether the product was
								sold or not.
							</p>
						</div>
					</div>
					<div className="mt-6 grid grid-cols-3 gap-3 grid-flow-row-dense">
						<Button
							text="Cancel"
							onClick={() => setDeleteModal(OpenClosedStates.Closed)}
							fullWidth
							color={ButtonColors.plain}
							className="border border-gray-300 dark:border-darkGray-500"
						/>
						<Button
							text="Delete / unsold"
							onClick={del.bind(null, false)}
							fullWidth
							loading={isDeleting}
						/>
						<Button
							text="Delete / sold"
							onClick={del.bind(null, true)}
							fullWidth
							loading={isDeleting}
						/>
					</div>
				</div>
			</Modal>
			<ConfirmModal
				state={soldModal}
				close={() => setSoldModal(OpenClosedStates.Closed)}
				description={`Are you sure you want to mark this product ${!!defaultValues?.sold ? 'for sale' : 'as sold'
					}?`}
				confirm={async () => {
					const res = await mark(!defaultValues?.sold);
					if (res) {
						setSoldModal(OpenClosedStates.Closed);
					}
				}}
				loading={isMarking}
			/>
			<ManufacturerColorModal
				state={manufacturerColorModal}
				setColors={setManufacturerColorsComplete}
				setModalState={setManufacturerColorModal}
				patchValue={patchValue}
				currentKey={currentKey}
				colorOptions={manufacturerColorsComplete}
				roofingType={value.roofingType}
				sidingType={value.sidingType}
			/>
			<SponsoredListingModal
				modalState={sponsoredListingModalState}
				setModalState={setSponsoredListingModalState}
				accountId={me.account.id}
				productId={value.id}
			/>
		</>
	);
};

export default ProductGeneral;
